body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  color: #333333;
  background: #757575;
}

a {
  text-decoration: none !important;
}
a:focus,
button:focus,
.custom-control-label::before {
  outline: 0 !important;
  box-shadow: none !important;
}
.x-small {
  font-size: x-small;
}
.scratched {
  text-decoration: line-through;
}
.underline {
  text-decoration: underline !important;
}
.underline-double {
  -webkit-text-decoration: underline double;
          text-decoration: underline double;
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-help {
  cursor: help;
}
.border-2 {
  border-width: 2px !important;
}
.rotate-90 {
  height: 20px;
  -webkit-transform: rotate(90deg) !important;
          transform: rotate(90deg) !important;
}
.intro {
  background-attachment: fixed;
  background-image: url(/static/media/intro.2ba23bd2.svg);
  background-position: top right;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.intro.alt {
  background-image: url(/static/media/intro-alt.bb947e0e.svg);
}
.text-pricing {
  color: #333333;
}
.text-info-light {
  color: #aeebf5;
}
.text-info-dark {
  color: #045663;
}
.text-green {
  color: #7eaf02 !important;
}
.text-light-green {
  color: #c8e381;
}
a.text-light-green:hover {
  color: #638806 !important;
}
.active-info-trans-bold a.active {
  color: #17a2b8 !important;
  font-weight: bold;
  background-color: transparent !important;
}
.active-info-trans a.active {
  color: #17a2b8 !important;
  background-color: transparent !important;
}
.active-info a.active {
  color: #fff !important;
  background-color: #17a2b8 !important;
}
.active-trans a.active {
  background-color: transparent !important;
}
.hover-white a:hover {
  color: #fff !important;
}
.hover-light:hover,
a:hover.hover-light {
  color: #dee2e6 !important;
}
.hover-row {
  pointer-events: none;
}
.hover-row:hover {
  background-color: #f0f1f2;
  border-radius: 0.25em;
}
.hover-row * {
  pointer-events: auto;
  background-color: transparent;
}
.bg-light-orange {
  background-color: #fffbf5;
}
.bg-new-darker {
  background-color: rgb(10, 10, 10);
}
.bg-new-dark {
  background-color: #0e0e0e !important;
}
.bg-grey {
  background-color: #f0f1f2 !important;
}
.bg-light-green {
  background-color: #c8e381;
}
.bg-grad-dark-info {
  background-image: linear-gradient(#0e0e0e, #17a2b8);
}

.bg-grad-info-white {
  background-image: linear-gradient(#17a2b8, #ffffff);
}

.bg-grad-white-info-2 {
  background-image: linear-gradient(#ffffff, #cdf4fa);
  border-bottom: 2px solid #bbd8dd;
}

.bg-transp {
  background-color: transparent;
}
.bg-image {
  background-image: url(/static/media/bgImg.fbc8609c.png);
  background-attachment: fixed;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  height: 50vh;
}
.bg-waves {
  background-color: #17a2b8;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1600 800'%3E%3Cg %3E%3Cpath fill='%235bb6c8' d='M486 705.8c-109.3-21.8-223.4-32.2-335.3-19.4C99.5 692.1 49 703 0 719.8V800h843.8c-115.9-33.2-230.8-68.1-347.6-92.2C492.8 707.1 489.4 706.5 486 705.8z'/%3E%3Cpath fill='%2385c8d5' d='M1600 0H0v719.8c49-16.8 99.5-27.8 150.7-33.5c111.9-12.7 226-2.4 335.3 19.4c3.4 0.7 6.8 1.4 10.2 2c116.8 24 231.7 59 347.6 92.2H1600V0z'/%3E%3Cpath fill='%23aadae3' d='M478.4 581c3.2 0.8 6.4 1.7 9.5 2.5c196.2 52.5 388.7 133.5 593.5 176.6c174.2 36.6 349.5 29.2 518.6-10.2V0H0v574.9c52.3-17.6 106.5-27.7 161.1-30.9C268.4 537.4 375.7 554.2 478.4 581z'/%3E%3Cpath fill='%23cdecf1' d='M0 0v429.4c55.6-18.4 113.5-27.3 171.4-27.7c102.8-0.8 203.2 22.7 299.3 54.5c3 1 5.9 2 8.9 3c183.6 62 365.7 146.1 562.4 192.1c186.7 43.7 376.3 34.4 557.9-12.6V0H0z'/%3E%3Cpath fill='%23f0feff' d='M181.8 259.4c98.2 6 191.9 35.2 281.3 72.1c2.8 1.1 5.5 2.3 8.3 3.4c171 71.6 342.7 158.5 531.3 207.7c198.8 51.8 403.4 40.8 597.3-14.8V0H0v283.2C59 263.6 120.6 255.7 181.8 259.4z'/%3E%3Cpath fill='%23f3feff' d='M1600 0H0v136.3c62.3-20.9 127.7-27.5 192.2-19.2c93.6 12.1 180.5 47.7 263.3 89.6c2.6 1.3 5.1 2.6 7.7 3.9c158.4 81.1 319.7 170.9 500.3 223.2c210.5 61 430.8 49 636.6-16.6V0z'/%3E%3Cpath fill='%23f6feff' d='M454.9 86.3C600.7 177 751.6 269.3 924.1 325c208.6 67.4 431.3 60.8 637.9-5.3c12.8-4.1 25.4-8.4 38.1-12.9V0H288.1c56 21.3 108.7 50.6 159.7 82C450.2 83.4 452.5 84.9 454.9 86.3z'/%3E%3Cpath fill='%23f9ffff' d='M1600 0H498c118.1 85.8 243.5 164.5 386.8 216.2c191.8 69.2 400 74.7 595 21.1c40.8-11.2 81.1-25.2 120.3-41.7V0z'/%3E%3Cpath fill='%23fcffff' d='M1397.5 154.8c47.2-10.6 93.6-25.3 138.6-43.8c21.7-8.9 43-18.8 63.9-29.5V0H643.4c62.9 41.7 129.7 78.2 202.1 107.4C1020.4 178.1 1214.2 196.1 1397.5 154.8z'/%3E%3Cpath fill='%23ffffff' d='M1315.3 72.4c75.3-12.6 148.9-37.1 216.8-72.4h-723C966.8 71 1144.7 101 1315.3 72.4z'/%3E%3C/g%3E%3C/svg%3E");
  background-attachment: fixed;
  background-size: cover;
  /* background by SVGBackgrounds.com */
}
.bg-sky {
  background-color: #0e0e0e;
background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1600 800'%3E%3Cg %3E%3Cpath fill='%23101618' d='M486 705.8c-109.3-21.8-223.4-32.2-335.3-19.4C99.5 692.1 49 703 0 719.8V800h843.8c-115.9-33.2-230.8-68.1-347.6-92.2C492.8 707.1 489.4 706.5 486 705.8z'/%3E%3Cpath fill='%23101d20' d='M1600 0H0v719.8c49-16.8 99.5-27.8 150.7-33.5c111.9-12.7 226-2.4 335.3 19.4c3.4 0.7 6.8 1.4 10.2 2c116.8 24 231.7 59 347.6 92.2H1600V0z'/%3E%3Cpath fill='%230e2429' d='M478.4 581c3.2 0.8 6.4 1.7 9.5 2.5c196.2 52.5 388.7 133.5 593.5 176.6c174.2 36.6 349.5 29.2 518.6-10.2V0H0v574.9c52.3-17.6 106.5-27.7 161.1-30.9C268.4 537.4 375.7 554.2 478.4 581z'/%3E%3Cpath fill='%230a2c32' d='M0 0v429.4c55.6-18.4 113.5-27.3 171.4-27.7c102.8-0.8 203.2 22.7 299.3 54.5c3 1 5.9 2 8.9 3c183.6 62 365.7 146.1 562.4 192.1c186.7 43.7 376.3 34.4 557.9-12.6V0H0z'/%3E%3Cpath fill='%2302333b' d='M181.8 259.4c98.2 6 191.9 35.2 281.3 72.1c2.8 1.1 5.5 2.3 8.3 3.4c171 71.6 342.7 158.5 531.3 207.7c198.8 51.8 403.4 40.8 597.3-14.8V0H0v283.2C59 263.6 120.6 255.7 181.8 259.4z'/%3E%3Cpath fill='%23023941' d='M1600 0H0v136.3c62.3-20.9 127.7-27.5 192.2-19.2c93.6 12.1 180.5 47.7 263.3 89.6c2.6 1.3 5.1 2.6 7.7 3.9c158.4 81.1 319.7 170.9 500.3 223.2c210.5 61 430.8 49 636.6-16.6V0z'/%3E%3Cpath fill='%23033e48' d='M454.9 86.3C600.7 177 751.6 269.3 924.1 325c208.6 67.4 431.3 60.8 637.9-5.3c12.8-4.1 25.4-8.4 38.1-12.9V0H288.1c56 21.3 108.7 50.6 159.7 82C450.2 83.4 452.5 84.9 454.9 86.3z'/%3E%3Cpath fill='%2303444f' d='M1600 0H498c118.1 85.8 243.5 164.5 386.8 216.2c191.8 69.2 400 74.7 595 21.1c40.8-11.2 81.1-25.2 120.3-41.7V0z'/%3E%3Cpath fill='%23044a55' d='M1397.5 154.8c47.2-10.6 93.6-25.3 138.6-43.8c21.7-8.9 43-18.8 63.9-29.5V0H643.4c62.9 41.7 129.7 78.2 202.1 107.4C1020.4 178.1 1214.2 196.1 1397.5 154.8z'/%3E%3Cpath fill='%2304505c' d='M1315.3 72.4c75.3-12.6 148.9-37.1 216.8-72.4h-723C966.8 71 1144.7 101 1315.3 72.4z'/%3E%3C/g%3E%3C/svg%3E");
background-attachment: fixed;
background-size: cover;
  /* background by SVGBackgrounds.com */
}
.bg-page-turn,
.bg-pricing {
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='350' height='350' viewBox='0 0 200 200'%3E%3Cdefs%3E%3ClinearGradient id='a' gradientUnits='userSpaceOnUse' x1='88' y1='88' x2='0' y2='0'%3E%3Cstop offset='0' stop-color='%230d5b67'/%3E%3Cstop offset='1' stop-color='%231490a4'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' gradientUnits='userSpaceOnUse' x1='75' y1='76' x2='168' y2='160'%3E%3Cstop offset='0' stop-color='%238f8f8f'/%3E%3Cstop offset='0.09' stop-color='%23b3b3b3'/%3E%3Cstop offset='0.18' stop-color='%23c9c9c9'/%3E%3Cstop offset='0.31' stop-color='%23dbdbdb'/%3E%3Cstop offset='0.44' stop-color='%23e8e8e8'/%3E%3Cstop offset='0.59' stop-color='%23f2f2f2'/%3E%3Cstop offset='0.75' stop-color='%23fafafa'/%3E%3Cstop offset='1' stop-color='%23FFFFFF'/%3E%3C/linearGradient%3E%3Cfilter id='c' x='0' y='0' width='200%25' height='200%25'%3E%3CfeGaussianBlur in='SourceGraphic' stdDeviation='12' /%3E%3C/filter%3E%3C/defs%3E%3Cpolygon fill='url(%23a)' points='0 174 0 0 174 0'/%3E%3Cpath fill='%23000' fill-opacity='.5' filter='url(%23c)' d='M121.8 174C59.2 153.1 0 174 0 174s63.5-73.8 87-94c24.4-20.9 87-80 87-80S107.9 104.4 121.8 174z'/%3E%3Cpath fill='url(%23b)' d='M142.7 142.7C59.2 142.7 0 174 0 174s42-66.3 74.9-99.3S174 0 174 0S142.7 62.6 142.7 142.7z'/%3E%3C/svg%3E");
  background-attachment: scroll;
  background-repeat: no-repeat;
  background-position: top left;
  /* background by SVGBackgrounds.com */
}

.btn-new-dark {
  color: #fff;
  background-color: #0e0e0e;
  border-color: #0e0e0e;
}
.btn-new-dark:hover {
  color: #17a2b8;
  background-color: #0e0e0e;
  border-color: #0e0e0e;
}
.border-new-dark {
  border-color: #0e0e0e !important;
}
.border-light-green {
  border-color: #c8e381 !important;
}

.major,
.pricing {
  position: relative;
}
.major:after {
  background: linear-gradient(to right, transparent, #000, transparent);
  border-radius: 0.2em;
  bottom: 0;
  content: "";
  height: 0.05em;
  position: absolute;
  right: 0;
  width: 100%;
}
.major.alt:after {
  background: linear-gradient(to right, transparent, #fff, transparent);
}

h1.pricing:after {
  background: linear-gradient(
    to right,
    transparent,
    #343a40,
    #343a40,
    #343a4075,
    transparent
  );
  border-radius: 0.2em;
  content: "";
  height: 0.05em;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 78%;
}

.home-img {
  position: relative;
}

.home-img img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  max-width: 700px;
  height: auto;
}
.logo {
  display: inline-block;

  /*border-radius: 100%;*/
  /*padding: 13px;*/
  /*border: solid 1px #343a40;*/
}

.logo img {
  width: 100px;
  height: auto;
}

.loading-img,
.loading-img-big,
.loading-img-sm {
  display: inline-block;
}

.loading-img-big img {
  width: 90px;
  height: auto;
}

.loading-img img {
  width: 45px;
  height: auto;
}

.loading-img-sm img {
  width: 25px;
  height: auto;
}
.loading:after {
  content: ".";
  -webkit-animation: dots 1s steps(5, end) infinite;
          animation: dots 1s steps(5, end) infinite;
}

svg.lines {
  left: 0;
  position: absolute;
  width: 100%;
  height: 100px;
  fill: #17a2b8;
}

svg.lines.alt {
  fill: #343a40;
}
svg.lines.green {
  fill: #c8e381;
}
svg.lines.white {
  fill: #ffffff;
}
svg.bottom {
  bottom: 0;
}
.rounded-drop {
  border-radius: 50%;
  border-top-left-radius: 0%;
}

.table-responsive::-webkit-scrollbar,
.scroll::-webkit-scrollbar,
.overflow-auto::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.white.table-responsive::-webkit-scrollbar-track,
.white.scroll::-webkit-scrollbar-track,
.white.overflow-auto::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.white.table-responsive::-webkit-scrollbar-thumb,
.white.scroll::-webkit-scrollbar-thumb,
.white.overflow-auto::-webkit-scrollbar-thumb {
  border-radius: 8px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.blue.table-responsive::-webkit-scrollbar-track,
.blue.scroll::-webkit-scrollbar-track,
.blue.overflow-auto::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #cdf4fa;
  border-radius: 8px;
}

.blue.table-responsive::-webkit-scrollbar-thumb,
.blue.scroll::-webkit-scrollbar-thumb,
.blue.overflow-auto::-webkit-scrollbar-thumb {
  border-radius: 8px;
  -webkit-box-shadow: inset 0 0 6px #c4f1f8;
}

.table-responsive::-webkit-scrollbar-track,
.scroll::-webkit-scrollbar-track,
.overflow-auto::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.4);
  border-radius: 8px;
}

.table-responsive::-webkit-scrollbar-thumb,
.scroll::-webkit-scrollbar-thumb,
.overflow-auto::-webkit-scrollbar-thumb {
  border-radius: 8px;
  -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.8);
}
.table-responsive::-webkit-scrollbar-corner,
.scroll::-webkit-scrollbar-corner,
.overflow-auto::-webkit-scrollbar-corner {
  background: transparent;
}

.page {
  margin: 55px auto 0;
}
.page-alt {
  margin-top: 55px;
  min-height: calc(100vh - 56px);
}
.modal.show {
  transition: opacity 160ms ease-out;
}
.modal {
  color: #333333;
  overflow-y: auto;
}
.modal-large {
  max-width: 85%;
}

.modal-content-slide-down {
  -webkit-animation-name: animatetop;
          animation-name: animatetop;
  -webkit-animation-duration: 0.1s;
          animation-duration: 0.1s;
}
.table.pricing th,
.table.pricing td {
  padding-left: 16px;
  padding-right: 16px;
}
.table.pricing thead th {
  padding-top: 16px;
}
.table.pricing tbody tr:last-child td {
  padding-bottom: 16px;
}
.table.pricing thead th:nth-child(4) {
  border: 2px solid #17a2b8;
  border-bottom: none;
}
.table.pricing thead tr td:nth-child(4),
.table.pricing tbody tr td:nth-child(4) {
  border: 2px solid #17a2b8;
  border-top: none;
  border-bottom: none;
}
.table.pricing tbody tr:last-child td:nth-child(4) {
  border-bottom: 2px solid #17a2b8;
}
.table.pricing.table-hover tr.no-hover:hover {
  background-color: inherit;
}
textarea:focus,
.dropdown-btn:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="search"]:focus {
  outline: none !important;
  border-color: rgba(23, 162, 184, 0.8) !important;
  border-radius: 0.25em;
  box-shadow: 0 1px 1px rgba(23, 162, 184, 0.075),
    0 0 8px rgba(23, 162, 184, 0.6) !important;
}
input[type="text"],
input[type="number"],
input[type="datetime-local"] {
  -moz-appearance: textfield;
  outline: none;
  box-shadow: none;
  border: none;
  background-color: transparent;
  width: 220px;
  height: 24px;
  color: #fff;
  text-align: center;
  padding-top: 0;
  padding-bottom: 0;
}
input.btn-bottom-outline-info,
input.btn-bottom-outline-info:focus {
  color: #17a2b8 !important;
  border-bottom: 1px solid #17a2b8 !important;
}
input.btn-bottom-outline-info::-webkit-input-placeholder, input.btn-bottom-outline-info:focus::-webkit-input-placeholder {
  color: #17a2b8 !important;
}
input.btn-bottom-outline-info::placeholder,
input.btn-bottom-outline-info:focus::placeholder {
  color: #17a2b8 !important;
}
input.btn-bottom-outline-info:hover::-webkit-input-placeholder {
  color: #117180 !important;
}
input.btn-bottom-outline-info:hover::placeholder {
  color: #117180 !important;
}
input.btn-bottom-outline-info:hover {
  color: #117180 !important;
  border-bottom: 1px solid #117180 !important;
}
input.form-control {
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  text-align: left;
  color: #495057 !important;
  background-color: #fff;
  border: 1px solid #d6d6d6 !important;
}
input.form-control:hover,
input.form-control:focus {
  color: #17a2b8 !important;
  border-color: #17a2b8 !important;
  border-radius: 0.25em !important;
}
input.h-33 {
  height: 33px !important;
}
input.w-100-p {
  width: 100% !important;
}
.custom-checkbox .custom-control-input:focus ~ .custom-control-label::before {
  border: solid 1px #17a2b8;
  background-color: #fff;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  border: solid 1px #17a2b8;
  background-color: #17a2b8;
}
.max-h-55-vh {
  max-height: 55vh;
}
.min-w-280 {
  min-width: 280px;
}
.max-w-450 {
  max-width: 450px;
}
.w-200 {
  width: 200px;
}
img.w-custom {
  max-width: 100%;
  width: 80%;
  height: auto;
  margin: auto;
}
.top-right-custom {
  top: 0.45em;
  right: 0.5em;
}
.nav-header {
  display: inline-block;
}
.small-nav {
  display: none;
}
.big-nav {
  display: block;
}
.z-index-big {
  z-index: 100000;
}
.z-index100 {
  z-index: 100;
}

#indicators {
  min-height: 525px;
}
#backtest {
  min-height: 424px;
}

@-webkit-keyframes scaleIn {
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
}

@keyframes scaleIn {
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
}
@-webkit-keyframes dots {
  0%,
  20% {
    color: #fff;
    text-shadow: 0.25em 0 0 #fff, 0.5em 0 0 #fff;
  }
  40% {
    color: #333333;
    text-shadow: 0.25em 0 0 #fff, 0.5em 0 0 #fff;
  }
  60% {
    text-shadow: 0.25em 0 0 #333333, 0.5em 0 0 #fff;
  }
  80%,
  100% {
    text-shadow: 0.25em 0 0 #333333, 0.5em 0 0 #333333;
  }
}
@keyframes dots {
  0%,
  20% {
    color: #fff;
    text-shadow: 0.25em 0 0 #fff, 0.5em 0 0 #fff;
  }
  40% {
    color: #333333;
    text-shadow: 0.25em 0 0 #fff, 0.5em 0 0 #fff;
  }
  60% {
    text-shadow: 0.25em 0 0 #333333, 0.5em 0 0 #fff;
  }
  80%,
  100% {
    text-shadow: 0.25em 0 0 #333333, 0.5em 0 0 #333333;
  }
}
@-webkit-keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}
@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}
@-webkit-keyframes blinker {
  50% {
    opacity: 0.25;
  }
}
@keyframes blinker {
  50% {
    opacity: 0.25;
  }
}
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes rotator {
  50% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes rotator {
  50% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@-webkit-keyframes slideInFromLeft {
  0% {
    -webkit-transform: translateX(-20%);
            transform: translateX(-20%);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
@keyframes slideInFromLeft {
  0% {
    -webkit-transform: translateX(-20%);
            transform: translateX(-20%);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@-webkit-keyframes slideInFromLeftFull {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes slideInFromLeftFull {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
@-webkit-keyframes slideInFromRight {
  0% {
    -webkit-transform: translateX(20%);
            transform: translateX(20%);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
@keyframes slideInFromRight {
  0% {
    -webkit-transform: translateX(20%);
            transform: translateX(20%);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
@-webkit-keyframes slideInFromRightFull {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
@keyframes slideInFromRightFull {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
@-webkit-keyframes slideInFromBottom {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@keyframes slideInFromBottom {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@-webkit-keyframes slideInFromBottomOpacity {
  0% {
    -webkit-transform: translateY(600%);
            transform: translateY(600%);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slideInFromBottomOpacity {
  0% {
    -webkit-transform: translateY(600%);
            transform: translateY(600%);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
.rotate-20 {
  -webkit-transform: rotate(-20deg);
          transform: rotate(-20deg);
}
.strech::after {
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  -webkit-animation: 1s ease-in 0s forwards scaleIn;
          animation: 1s ease-in 0s forwards scaleIn;
}
.blink {
  -webkit-animation: 2s linear infinite blinker;
          animation: 2s linear infinite blinker;
}
.blink-slow {
  -webkit-animation: 2.5s linear infinite blinker;
          animation: 2.5s linear infinite blinker;
}
.rotate {
  -webkit-animation: 3.5s ease-in-out infinite rotator;
          animation: 3.5s ease-in-out infinite rotator;
}
.fade-in {
  -webkit-animation: 0.7s ease-out 0s 1 fadeIn;
          animation: 0.7s ease-out 0s 1 fadeIn;
}
.left-slide {
  -webkit-animation: 1.5s ease-out 0s 1 slideInFromLeft;
          animation: 1.5s ease-out 0s 1 slideInFromLeft;
}
.left-slide2 {
  -webkit-animation: 1.6s ease-out 0s 1 slideInFromLeft;
          animation: 1.6s ease-out 0s 1 slideInFromLeft;
}
.left-slide-full {
  -webkit-animation: 1s ease-out 0s 1 slideInFromLeftFull;
          animation: 1s ease-out 0s 1 slideInFromLeftFull;
}
.right-slide {
  -webkit-animation: 1.5s ease-out 0s 1 slideInFromRight;
          animation: 1.5s ease-out 0s 1 slideInFromRight;
}
.right-slide-full {
  -webkit-animation: 1s ease-out 0s 1 slideInFromRightFull;
          animation: 1s ease-out 0s 1 slideInFromRightFull;
}
.bottom-slide1 {
  -webkit-animation: 0.8s ease-out 0s 1 slideInFromBottom;
          animation: 0.8s ease-out 0s 1 slideInFromBottom;
}
.bottom-slide2 {
  -webkit-animation: 1.6s ease-out 0s 1 slideInFromBottom;
          animation: 1.6s ease-out 0s 1 slideInFromBottom;
}
.bottom-slide3 {
  -webkit-animation: 2.4s ease-out 0s 1 slideInFromBottom;
          animation: 2.4s ease-out 0s 1 slideInFromBottom;
}
.bottom-slide4 {
  -webkit-animation: 3.2s ease-out 0s 1 slideInFromBottom;
          animation: 3.2s ease-out 0s 1 slideInFromBottom;
}
.bottom-slide5 {
  -webkit-animation: 3.5s ease-out 0s 1 slideInFromBottomOpacity;
          animation: 3.5s ease-out 0s 1 slideInFromBottomOpacity;
}

@media (min-height: 900px) {
  .h-custom {
    height: 82vh;
  }
}
@media (max-height: 900px) {
  .h-custom {
    height: 76vh;
  }
  .h-nav {
    max-height: 72vh;
  }
}
@media (max-height: 800px) {
  .h-custom {
    height: 78vh;
  }
  .h-nav {
    max-height: 70vh;
  }
}
@media (max-height: 700px) {
  .h-custom {
    height: 90vh;
  }
  .h-nav {
    max-height: 82vh;
  }
}
@media (max-height: 600px) {
  .h-custom {
    height: 120vh;
  }
  .h-nav {
    max-height: 110vh;
  }
}
@media (min-width: 1500px) {
  .container {
    max-width: 80% !important;
  }
}
@media (min-width: 992px) {
  .border-right-lg {
    border-right: 1px solid #dee2e6;
  }
  .border-left-lg {
    border-left: 1px solid #dee2e6;
  }
}
@media (min-width: 768px) {
  .border-bottom-md-0 {
    border-top: 1px solid #17a2b8 !important;
    border-left: 1px solid #17a2b8 !important;
    border-right: 1px solid #17a2b8 !important;
    border-bottom: none !important;
  }
  .border-right-md {
    border-right: 1px solid #dee2e6;
  }
  .border-left-md {
    border-left: 1px solid #dee2e6;
  }
  .table tr td:first-child,
  .table tr th:first-child {
    padding-right: 32px;
  }
  .table.equal-cols {
    table-layout: fixed;
    width: 100%;
  }
  .display-3 {
    font-size: 4.5rem;
    font-weight: 300;
    line-height: 1.2;
  }
  .w-md-75 {
    width: 75%;
  }
}
@media (max-width: 1500px) {
  img.w-custom {
    width: 85%;
  }
}

@media (max-width: 1200px) {
  .bg-pricing {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='330' height='330' viewBox='0 0 200 200'%3E%3Cdefs%3E%3ClinearGradient id='a' gradientUnits='userSpaceOnUse' x1='88' y1='88' x2='0' y2='0'%3E%3Cstop offset='0' stop-color='%230d5b67'/%3E%3Cstop offset='1' stop-color='%231490a4'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' gradientUnits='userSpaceOnUse' x1='75' y1='76' x2='168' y2='160'%3E%3Cstop offset='0' stop-color='%238f8f8f'/%3E%3Cstop offset='0.09' stop-color='%23b3b3b3'/%3E%3Cstop offset='0.18' stop-color='%23c9c9c9'/%3E%3Cstop offset='0.31' stop-color='%23dbdbdb'/%3E%3Cstop offset='0.44' stop-color='%23e8e8e8'/%3E%3Cstop offset='0.59' stop-color='%23f2f2f2'/%3E%3Cstop offset='0.75' stop-color='%23fafafa'/%3E%3Cstop offset='1' stop-color='%23FFFFFF'/%3E%3C/linearGradient%3E%3Cfilter id='c' x='0' y='0' width='200%25' height='200%25'%3E%3CfeGaussianBlur in='SourceGraphic' stdDeviation='12' /%3E%3C/filter%3E%3C/defs%3E%3Cpolygon fill='url(%23a)' points='0 174 0 0 174 0'/%3E%3Cpath fill='%23000' fill-opacity='.5' filter='url(%23c)' d='M121.8 174C59.2 153.1 0 174 0 174s63.5-73.8 87-94c24.4-20.9 87-80 87-80S107.9 104.4 121.8 174z'/%3E%3Cpath fill='url(%23b)' d='M142.7 142.7C59.2 142.7 0 174 0 174s42-66.3 74.9-99.3S174 0 174 0S142.7 62.6 142.7 142.7z'/%3E%3C/svg%3E");
    /* background by SVGBackgrounds.com */
  }
  img.w-custom {
    width: 100%;
  }
  .home-img img {
    top: 50px;
  }
}
@media (max-width: 1100px) {
  .bg-pricing {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='310' height='310' viewBox='0 0 200 200'%3E%3Cdefs%3E%3ClinearGradient id='a' gradientUnits='userSpaceOnUse' x1='88' y1='88' x2='0' y2='0'%3E%3Cstop offset='0' stop-color='%230d5b67'/%3E%3Cstop offset='1' stop-color='%231490a4'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' gradientUnits='userSpaceOnUse' x1='75' y1='76' x2='168' y2='160'%3E%3Cstop offset='0' stop-color='%238f8f8f'/%3E%3Cstop offset='0.09' stop-color='%23b3b3b3'/%3E%3Cstop offset='0.18' stop-color='%23c9c9c9'/%3E%3Cstop offset='0.31' stop-color='%23dbdbdb'/%3E%3Cstop offset='0.44' stop-color='%23e8e8e8'/%3E%3Cstop offset='0.59' stop-color='%23f2f2f2'/%3E%3Cstop offset='0.75' stop-color='%23fafafa'/%3E%3Cstop offset='1' stop-color='%23FFFFFF'/%3E%3C/linearGradient%3E%3Cfilter id='c' x='0' y='0' width='200%25' height='200%25'%3E%3CfeGaussianBlur in='SourceGraphic' stdDeviation='12' /%3E%3C/filter%3E%3C/defs%3E%3Cpolygon fill='url(%23a)' points='0 174 0 0 174 0'/%3E%3Cpath fill='%23000' fill-opacity='.5' filter='url(%23c)' d='M121.8 174C59.2 153.1 0 174 0 174s63.5-73.8 87-94c24.4-20.9 87-80 87-80S107.9 104.4 121.8 174z'/%3E%3Cpath fill='url(%23b)' d='M142.7 142.7C59.2 142.7 0 174 0 174s42-66.3 74.9-99.3S174 0 174 0S142.7 62.6 142.7 142.7z'/%3E%3C/svg%3E");
    /* background by SVGBackgrounds.com */
  }
}
@media (max-width: 991px) {
  .bg-pricing {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='270' height='270' viewBox='0 0 200 200'%3E%3Cdefs%3E%3ClinearGradient id='a' gradientUnits='userSpaceOnUse' x1='88' y1='88' x2='0' y2='0'%3E%3Cstop offset='0' stop-color='%230d5b67'/%3E%3Cstop offset='1' stop-color='%231490a4'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' gradientUnits='userSpaceOnUse' x1='75' y1='76' x2='168' y2='160'%3E%3Cstop offset='0' stop-color='%238f8f8f'/%3E%3Cstop offset='0.09' stop-color='%23b3b3b3'/%3E%3Cstop offset='0.18' stop-color='%23c9c9c9'/%3E%3Cstop offset='0.31' stop-color='%23dbdbdb'/%3E%3Cstop offset='0.44' stop-color='%23e8e8e8'/%3E%3Cstop offset='0.59' stop-color='%23f2f2f2'/%3E%3Cstop offset='0.75' stop-color='%23fafafa'/%3E%3Cstop offset='1' stop-color='%23FFFFFF'/%3E%3C/linearGradient%3E%3Cfilter id='c' x='0' y='0' width='200%25' height='200%25'%3E%3CfeGaussianBlur in='SourceGraphic' stdDeviation='12' /%3E%3C/filter%3E%3C/defs%3E%3Cpolygon fill='url(%23a)' points='0 174 0 0 174 0'/%3E%3Cpath fill='%23000' fill-opacity='.5' filter='url(%23c)' d='M121.8 174C59.2 153.1 0 174 0 174s63.5-73.8 87-94c24.4-20.9 87-80 87-80S107.9 104.4 121.8 174z'/%3E%3Cpath fill='url(%23b)' d='M142.7 142.7C59.2 142.7 0 174 0 174s42-66.3 74.9-99.3S174 0 174 0S142.7 62.6 142.7 142.7z'/%3E%3C/svg%3E");
    /* background by SVGBackgrounds.com */
  }
  .home-img img {
    top: 120px;
  }
  .modal-large {
    margin-left: auto;
    margin-right: auto;
    max-width: 90%;
  }
  .border-top-max-lg {
    border-top: 1px solid #dee2e6;
  }
  .border-bottom-max-lg {
    border-bottom: 1px solid #dee2e6;
  }
}

@media (max-width: 896px) {
  .bg-pricing {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='240' height='240' viewBox='0 0 200 200'%3E%3Cdefs%3E%3ClinearGradient id='a' gradientUnits='userSpaceOnUse' x1='88' y1='88' x2='0' y2='0'%3E%3Cstop offset='0' stop-color='%230d5b67'/%3E%3Cstop offset='1' stop-color='%231490a4'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' gradientUnits='userSpaceOnUse' x1='75' y1='76' x2='168' y2='160'%3E%3Cstop offset='0' stop-color='%238f8f8f'/%3E%3Cstop offset='0.09' stop-color='%23b3b3b3'/%3E%3Cstop offset='0.18' stop-color='%23c9c9c9'/%3E%3Cstop offset='0.31' stop-color='%23dbdbdb'/%3E%3Cstop offset='0.44' stop-color='%23e8e8e8'/%3E%3Cstop offset='0.59' stop-color='%23f2f2f2'/%3E%3Cstop offset='0.75' stop-color='%23fafafa'/%3E%3Cstop offset='1' stop-color='%23FFFFFF'/%3E%3C/linearGradient%3E%3Cfilter id='c' x='0' y='0' width='200%25' height='200%25'%3E%3CfeGaussianBlur in='SourceGraphic' stdDeviation='12' /%3E%3C/filter%3E%3C/defs%3E%3Cpolygon fill='url(%23a)' points='0 174 0 0 174 0'/%3E%3Cpath fill='%23000' fill-opacity='.5' filter='url(%23c)' d='M121.8 174C59.2 153.1 0 174 0 174s63.5-73.8 87-94c24.4-20.9 87-80 87-80S107.9 104.4 121.8 174z'/%3E%3Cpath fill='url(%23b)' d='M142.7 142.7C59.2 142.7 0 174 0 174s42-66.3 74.9-99.3S174 0 174 0S142.7 62.6 142.7 142.7z'/%3E%3C/svg%3E");
    /* background by SVGBackgrounds.com */
  }
  #topNavLogoText {
    display: none;
  }
}

@media (max-width: 767px) {
  .bg-pricing {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='200' height='200' viewBox='0 0 200 200'%3E%3Cdefs%3E%3ClinearGradient id='a' gradientUnits='userSpaceOnUse' x1='88' y1='88' x2='0' y2='0'%3E%3Cstop offset='0' stop-color='%230d5b67'/%3E%3Cstop offset='1' stop-color='%231490a4'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' gradientUnits='userSpaceOnUse' x1='75' y1='76' x2='168' y2='160'%3E%3Cstop offset='0' stop-color='%238f8f8f'/%3E%3Cstop offset='0.09' stop-color='%23b3b3b3'/%3E%3Cstop offset='0.18' stop-color='%23c9c9c9'/%3E%3Cstop offset='0.31' stop-color='%23dbdbdb'/%3E%3Cstop offset='0.44' stop-color='%23e8e8e8'/%3E%3Cstop offset='0.59' stop-color='%23f2f2f2'/%3E%3Cstop offset='0.75' stop-color='%23fafafa'/%3E%3Cstop offset='1' stop-color='%23FFFFFF'/%3E%3C/linearGradient%3E%3Cfilter id='c' x='0' y='0' width='200%25' height='200%25'%3E%3CfeGaussianBlur in='SourceGraphic' stdDeviation='12' /%3E%3C/filter%3E%3C/defs%3E%3Cpolygon fill='url(%23a)' points='0 174 0 0 174 0'/%3E%3Cpath fill='%23000' fill-opacity='.5' filter='url(%23c)' d='M121.8 174C59.2 153.1 0 174 0 174s63.5-73.8 87-94c24.4-20.9 87-80 87-80S107.9 104.4 121.8 174z'/%3E%3Cpath fill='url(%23b)' d='M142.7 142.7C59.2 142.7 0 174 0 174s42-66.3 74.9-99.3S174 0 174 0S142.7 62.6 142.7 142.7z'/%3E%3C/svg%3E");
    /* background by SVGBackgrounds.com */
  }
  h1.pricing:after {
    width: 78%;
  }
  .table tr td:first-child,
  .table tr th:first-child {
    border-right: 1px solid #dee2e6;
  }
  .table tr td:nth-child(2),
  .table tr th:nth-child(2) {
    padding-left: 16px;
  }
  .display-3 {
    font-size: 3.5rem;
    font-weight: 300;
    line-height: 1;
  }
  #topNavLogoText {
    display: inline;
  }

  .right-slide-full,
  .left-slide-full {
    -webkit-animation: none;
            animation: none;
  }

  .w-md-50 {
    width: 50%;
    margin: auto;
  }
  .h-custom {
    height: auto;
  }
  .navbar {
    padding: 0;
  }
  .nav-header {
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 0.4rem 1rem;
    z-index: 1001;
    flex-direction: row;
  }

  .small-nav {
    display: block;
    z-index: 100;
  }
  .big-nav {
    display: none;
  }

  #topNavLogoText {
    display: inline;
  }

  #topNav {
    position: absolute;
    width: 100%;
    display: none;
    top: 55px;
    left: 0;
    width: 100%;
    z-index: 1000;
  }
  #topNav .nav-menu {
    background-image: linear-gradient(to top, #2e2e2e, #0e0e0e, #2e2e2e);
  }
  .bar1,
  .bar2,
  .bar3 {
    width: 1.5em;
    height: 0.25em;
    line-height: 0.25em;
    background-color: #fff;
    margin: 0.25em 0;
    transition: 0.4s;
  }

  .sub-menu .bar1,
  .sub-menu .bar2,
  .sub-menu .bar3 {
    width: 1.2em;
    height: 0.2em;
    margin: 0em 0 0.2em 0;
    line-height: auto;
    background-color: #17a2b8;
  }

  .change .bar1 {
    -webkit-transform: rotate(-45deg) translate(-0.34375em, 0.3125em);
    transform: rotate(-45deg) translate(-0.34375em, 0.3125em);
  }

  .change .bar2 {
    opacity: 0;
  }

  .change .bar3 {
    -webkit-transform: rotate(45deg) translate(-0.375em, -0.375em);
    transform: rotate(45deg) translate(-0.375em, -0.375em);
  }

  .sub-menu .change .bar1 {
    -webkit-transform: rotate(-45deg) translate(-0.3em, 0em);
    transform: rotate(-45deg) translate(-0.3em, 0.25em);
  }

  .sub-menu .change .bar3 {
    -webkit-transform: rotate(45deg) translate(-0.3em, 0em);
    transform: rotate(45deg) translate(-0.3em, -0.3em);
  }

  .modal-large {
    max-width: calc(100% - 16px);
  }

  #indicators {
    min-height: 0px;
  }
  #backtest {
    min-height: 0px;
  }
}
@media (max-width: 640px) {
  .bg-pricing {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='190' height='190' viewBox='0 0 200 200'%3E%3Cdefs%3E%3ClinearGradient id='a' gradientUnits='userSpaceOnUse' x1='88' y1='88' x2='0' y2='0'%3E%3Cstop offset='0' stop-color='%230d5b67'/%3E%3Cstop offset='1' stop-color='%231490a4'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' gradientUnits='userSpaceOnUse' x1='75' y1='76' x2='168' y2='160'%3E%3Cstop offset='0' stop-color='%238f8f8f'/%3E%3Cstop offset='0.09' stop-color='%23b3b3b3'/%3E%3Cstop offset='0.18' stop-color='%23c9c9c9'/%3E%3Cstop offset='0.31' stop-color='%23dbdbdb'/%3E%3Cstop offset='0.44' stop-color='%23e8e8e8'/%3E%3Cstop offset='0.59' stop-color='%23f2f2f2'/%3E%3Cstop offset='0.75' stop-color='%23fafafa'/%3E%3Cstop offset='1' stop-color='%23FFFFFF'/%3E%3C/linearGradient%3E%3Cfilter id='c' x='0' y='0' width='200%25' height='200%25'%3E%3CfeGaussianBlur in='SourceGraphic' stdDeviation='12' /%3E%3C/filter%3E%3C/defs%3E%3Cpolygon fill='url(%23a)' points='0 174 0 0 174 0'/%3E%3Cpath fill='%23000' fill-opacity='.5' filter='url(%23c)' d='M121.8 174C59.2 153.1 0 174 0 174s63.5-73.8 87-94c24.4-20.9 87-80 87-80S107.9 104.4 121.8 174z'/%3E%3Cpath fill='url(%23b)' d='M142.7 142.7C59.2 142.7 0 174 0 174s42-66.3 74.9-99.3S174 0 174 0S142.7 62.6 142.7 142.7z'/%3E%3C/svg%3E");
    /* background by SVGBackgrounds.com */
  }
  h1.pricing:after {
    width: 76%;
  }
}
@media (max-width: 575px) {
  .bg-pricing {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='180' height='180' viewBox='0 0 200 200'%3E%3Cdefs%3E%3ClinearGradient id='a' gradientUnits='userSpaceOnUse' x1='88' y1='88' x2='0' y2='0'%3E%3Cstop offset='0' stop-color='%230d5b67'/%3E%3Cstop offset='1' stop-color='%231490a4'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' gradientUnits='userSpaceOnUse' x1='75' y1='76' x2='168' y2='160'%3E%3Cstop offset='0' stop-color='%238f8f8f'/%3E%3Cstop offset='0.09' stop-color='%23b3b3b3'/%3E%3Cstop offset='0.18' stop-color='%23c9c9c9'/%3E%3Cstop offset='0.31' stop-color='%23dbdbdb'/%3E%3Cstop offset='0.44' stop-color='%23e8e8e8'/%3E%3Cstop offset='0.59' stop-color='%23f2f2f2'/%3E%3Cstop offset='0.75' stop-color='%23fafafa'/%3E%3Cstop offset='1' stop-color='%23FFFFFF'/%3E%3C/linearGradient%3E%3Cfilter id='c' x='0' y='0' width='200%25' height='200%25'%3E%3CfeGaussianBlur in='SourceGraphic' stdDeviation='12' /%3E%3C/filter%3E%3C/defs%3E%3Cpolygon fill='url(%23a)' points='0 174 0 0 174 0'/%3E%3Cpath fill='%23000' fill-opacity='.5' filter='url(%23c)' d='M121.8 174C59.2 153.1 0 174 0 174s63.5-73.8 87-94c24.4-20.9 87-80 87-80S107.9 104.4 121.8 174z'/%3E%3Cpath fill='url(%23b)' d='M142.7 142.7C59.2 142.7 0 174 0 174s42-66.3 74.9-99.3S174 0 174 0S142.7 62.6 142.7 142.7z'/%3E%3C/svg%3E");
    /* background by SVGBackgrounds.com */
  }
  h1.pricing:after {
    width: 76%;
  }
  label {
    margin-bottom: 0;
  }
}
@media (max-width: 525px) {
  .bg-pricing {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='170' height='170' viewBox='0 0 200 200'%3E%3Cdefs%3E%3ClinearGradient id='a' gradientUnits='userSpaceOnUse' x1='88' y1='88' x2='0' y2='0'%3E%3Cstop offset='0' stop-color='%230d5b67'/%3E%3Cstop offset='1' stop-color='%231490a4'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' gradientUnits='userSpaceOnUse' x1='75' y1='76' x2='168' y2='160'%3E%3Cstop offset='0' stop-color='%238f8f8f'/%3E%3Cstop offset='0.09' stop-color='%23b3b3b3'/%3E%3Cstop offset='0.18' stop-color='%23c9c9c9'/%3E%3Cstop offset='0.31' stop-color='%23dbdbdb'/%3E%3Cstop offset='0.44' stop-color='%23e8e8e8'/%3E%3Cstop offset='0.59' stop-color='%23f2f2f2'/%3E%3Cstop offset='0.75' stop-color='%23fafafa'/%3E%3Cstop offset='1' stop-color='%23FFFFFF'/%3E%3C/linearGradient%3E%3Cfilter id='c' x='0' y='0' width='200%25' height='200%25'%3E%3CfeGaussianBlur in='SourceGraphic' stdDeviation='12' /%3E%3C/filter%3E%3C/defs%3E%3Cpolygon fill='url(%23a)' points='0 174 0 0 174 0'/%3E%3Cpath fill='%23000' fill-opacity='.5' filter='url(%23c)' d='M121.8 174C59.2 153.1 0 174 0 174s63.5-73.8 87-94c24.4-20.9 87-80 87-80S107.9 104.4 121.8 174z'/%3E%3Cpath fill='url(%23b)' d='M142.7 142.7C59.2 142.7 0 174 0 174s42-66.3 74.9-99.3S174 0 174 0S142.7 62.6 142.7 142.7z'/%3E%3C/svg%3E");
    /* background by SVGBackgrounds.com */
  }
  h1.pricing:after {
    width: 74%;
  }
}
@media (max-width: 475px) {
  .bg-pricing {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='140' height='140' viewBox='0 0 200 200'%3E%3Cdefs%3E%3ClinearGradient id='a' gradientUnits='userSpaceOnUse' x1='88' y1='88' x2='0' y2='0'%3E%3Cstop offset='0' stop-color='%230d5b67'/%3E%3Cstop offset='1' stop-color='%231490a4'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' gradientUnits='userSpaceOnUse' x1='75' y1='76' x2='168' y2='160'%3E%3Cstop offset='0' stop-color='%238f8f8f'/%3E%3Cstop offset='0.09' stop-color='%23b3b3b3'/%3E%3Cstop offset='0.18' stop-color='%23c9c9c9'/%3E%3Cstop offset='0.31' stop-color='%23dbdbdb'/%3E%3Cstop offset='0.44' stop-color='%23e8e8e8'/%3E%3Cstop offset='0.59' stop-color='%23f2f2f2'/%3E%3Cstop offset='0.75' stop-color='%23fafafa'/%3E%3Cstop offset='1' stop-color='%23FFFFFF'/%3E%3C/linearGradient%3E%3Cfilter id='c' x='0' y='0' width='200%25' height='200%25'%3E%3CfeGaussianBlur in='SourceGraphic' stdDeviation='12' /%3E%3C/filter%3E%3C/defs%3E%3Cpolygon fill='url(%23a)' points='0 174 0 0 174 0'/%3E%3Cpath fill='%23000' fill-opacity='.5' filter='url(%23c)' d='M121.8 174C59.2 153.1 0 174 0 174s63.5-73.8 87-94c24.4-20.9 87-80 87-80S107.9 104.4 121.8 174z'/%3E%3Cpath fill='url(%23b)' d='M142.7 142.7C59.2 142.7 0 174 0 174s42-66.3 74.9-99.3S174 0 174 0S142.7 62.6 142.7 142.7z'/%3E%3C/svg%3E");
    /* background by SVGBackgrounds.com */
  }
  h1.pricing:after {
    width: 76%;
  }
}
@media (max-width: 410px) {
  .bg-pricing {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='120' height='120' viewBox='0 0 200 200'%3E%3Cdefs%3E%3ClinearGradient id='a' gradientUnits='userSpaceOnUse' x1='88' y1='88' x2='0' y2='0'%3E%3Cstop offset='0' stop-color='%230d5b67'/%3E%3Cstop offset='1' stop-color='%231490a4'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' gradientUnits='userSpaceOnUse' x1='75' y1='76' x2='168' y2='160'%3E%3Cstop offset='0' stop-color='%238f8f8f'/%3E%3Cstop offset='0.09' stop-color='%23b3b3b3'/%3E%3Cstop offset='0.18' stop-color='%23c9c9c9'/%3E%3Cstop offset='0.31' stop-color='%23dbdbdb'/%3E%3Cstop offset='0.44' stop-color='%23e8e8e8'/%3E%3Cstop offset='0.59' stop-color='%23f2f2f2'/%3E%3Cstop offset='0.75' stop-color='%23fafafa'/%3E%3Cstop offset='1' stop-color='%23FFFFFF'/%3E%3C/linearGradient%3E%3Cfilter id='c' x='0' y='0' width='200%25' height='200%25'%3E%3CfeGaussianBlur in='SourceGraphic' stdDeviation='12' /%3E%3C/filter%3E%3C/defs%3E%3Cpolygon fill='url(%23a)' points='0 174 0 0 174 0'/%3E%3Cpath fill='%23000' fill-opacity='.5' filter='url(%23c)' d='M121.8 174C59.2 153.1 0 174 0 174s63.5-73.8 87-94c24.4-20.9 87-80 87-80S107.9 104.4 121.8 174z'/%3E%3Cpath fill='url(%23b)' d='M142.7 142.7C59.2 142.7 0 174 0 174s42-66.3 74.9-99.3S174 0 174 0S142.7 62.6 142.7 142.7z'/%3E%3C/svg%3E");
    /* background by SVGBackgrounds.com */
  }
  h1.pricing:after {
    width: 90%;
  }
}
@media (max-width: 375px) {
  .bg-pricing {
    background-image: none;
  }
  h1.pricing:after {
    background: linear-gradient(to right, #fff, #343a40, #fff);
    width: 100%;
  }
  .display-3 {
    font-size: 2.6rem;
    font-weight: 300;
    line-height: 1;
  }
}

